<template>
  <div class="adapter-form-artcontribution">
    <div class="adapter-form-artcontribution__inner">
      <UtilRendererStoryblok
        :data="content"
        :html-tag="'div'"
        class-name="''"
        :disable-grid="true"
      />

      <UtilRendererForm
        :fields="fields"
        :submit-text="submitText"
        :error-message="errorMessage"
        :success-message="successMessage"
        :reset-text="resetText"
        :callback="onSubmit"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Array,
        default: () => [],
    },

    fields: {
        type: Array,
        default: () => [],
    },

    submitText: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    successMessage: {
        type: String,
        default: '',
    },

    resetText: {
        type: String,
        default: '',
    },

    type: {
        type: String,
        default: 'art',
    },
});

const selectedPosition = useCookie('selected-position');
const client = useStrapiClient();

const onSubmit = async (data) => {
    const body = {
        ...data,
        title: props.type === 'art' ? data.art_title : data.name,
        group: selectedPosition.value?.group ?? -1,
        sector: selectedPosition.value?.sector ?? -1,
        assigned_to: props.type === 'art' ? 'table' : data.assigned_to,
        type: props.type,
    };

    delete body.image;

    const formData = new FormData();
    formData.append('image', data.image.file, data.image.file.name);

    Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
    });

    await client('/submissions', {
        method: 'POST',
        body: formData,
    });

    selectedPosition.value = null;
    await navigateTo('/');
};
</script>

<style lang="scss" scoped>
.adapter-form-artcontribution {
    @include wrapper-layout;
    @include grid-layout();

    padding-right: var(--page-padding);
    padding-left: var(--page-padding);
}

.adapter-form-artcontribution__inner {
    @include default-content-columns();
    @include fluid-simple('border-radius', 15px, 30px);
    @include fluid-simple('padding-top', 20px, 50px);
    @include fluid-simple('padding-bottom', 55px, 50px);
    @include fluid-simple('padding-left', 20px, 50px);
    @include fluid-simple('padding-right', 20px, 50px);

    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--c-white);
    row-gap: 15px;
}
</style>
