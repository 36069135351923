<template>
  <div
    class="atom-input atom-input-checkbox__wrapper"
    :class="{
      'is-disabled': isDisabled,
      'has-error': showErrors,
    }"
    @focusin="setHover(true)"
    @focus="setFocus(true)"
    @focusout="setFocus(false)"
  >
    <div
      class="atom-input-checkbox__box"
      :class="{ 'is-active': inputValue }"
    />

    <input
      :id="name"
      v-model="inputValue"
      type="checkbox"
      tabindex="-1"
      class="atom-input-checkbox__input"
    />

    <label
      class="atom-input-checkbox__label"
      :for="name"
    >
      <AtomTextRichtext
        :html="label"
        class="atom-input-checkbox__label"
        text-color="color"
      />
    </label>

    <transition name="fade-in-slow">
      <AtomInputErrors
        v-if="showErrors"
        :errors="errors"
        :is-extended="showErrorList"
        :is-checkbox="true"
      />
    </transition>
  </div>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
          Validations
      */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
          Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
          Styles
    */
    color: {
        type: String,
        default: 'var(--c-white)',
    },
});

const { color } = toRefs(props);

/*
      Intialze formfield
  */
const errors = ref([]);
const inputValue = ref('');

const setInitialValue = () => {
    inputValue.value = props.initialValue;
};

setInitialValue();

/*
      Handle input
  */
const emit = defineEmits(['set-input', 'set-error', 'on-reset']);

/* Watch input */
const isResetting = ref(false);
const handleData = (value) => {
    if (props.isDisabled) return;

    if (isResetting.value) {
        errors.value = [];
        isResetting.value = false;
        return;
    }

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

handleData(inputValue.value);

/*
      States
  */
const {
    setHover,
    setFocus,
    showErrors,
    showErrorList,
} = useFormFieldStates(inputValue, errors, props);

const resetValue = () => {
    isResetting.value = true;
    inputValue.value = props.initialValue || '';
    emit('on-reset');
};

defineExpose({
    resetValue,
});
</script>

<style lang="scss" scoped>
.atom-input-checkbox__wrapper {
    @include formFieldWrapper;
    @include fluid('--checkbox-size', 15px, 15px, 20px);

    display: flex;
    padding-right: 60px;
    color: var(--color);
    column-gap: 10px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--regular);
    line-height: var(--l-height--a);

    --color: v-bind(color);

    .block-registration-newsletter & {
        margin-top: 10px;
    }

    &.is-disabled {
        cursor: default;
        opacity: 0.2;
        pointer-events: none;
    }
}

.atom-input-checkbox__input {
    position: absolute;
    left: 0;
    width: var(--checkbox-size);
    min-width: var(--checkbox-size);
    height: var(--checkbox-size);
    margin-top: 2px;
    cursor: pointer;
    opacity: 0;

    .is-disabled & {
        cursor: default;
    }
}

.atom-input-checkbox__box {
    position: relative;
    width: var(--checkbox-size);
    min-width: var(--checkbox-size);
    height: var(--checkbox-size);
    border: 2px solid var(--color);
    margin-top: 2px;
    pointer-events: none;

    &:after {
        @include fluid('height', 5px, 5px, 6px);
        @include fluid('width', 5px, 5px, 6px);

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background: var(--color);
        content: '';
        opacity: 0;
        transform: scale(0.25);
        transition: opacity 0.2s var(--ease-out--quart), transform 0.2s var(--ease-out--quart);
    }

    &.is-active {
        &:after {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.atom-input-checkbox__label {
    margin-top: -1px;
    cursor: pointer;
    user-select: none;

    ::v-deep(.atom-richtext__inner) {
        font-size: var(--f-size--a);
        font-weight: var(--f-weight--regular);
        line-height: var(--l-height--a);
    }

    .is-disabled & {
        cursor: default;
    }
}
</style>
