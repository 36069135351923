<template>
  <div>
    <UtilRendererStoryblok
      v-if="mainContent"
      :data="mainContent"
    />
    <BlockOverlayContribution
      v-else-if="submissionData"
      :image="submissionData.image"
      :art-title="submissionData.artTitle"
      :artist-name="submissionData.artistName"
      :title="submissionData.title"
      :type="submissionData.type"
      :description="submissionData.description"
    />
  </div>
</template>

<script setup>

definePageMeta({
    pageTransition: {
        name: 'threesjs',
        mode: 'out-in',

        onEnter: async (el, done) => {
            const { gsap } = useGsap();

            if (el) {
                gsap.fromTo(el, {
                    scale: 1.5,
                    autoAlpha: 0,
                }, {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 0.6,
                    ease: 'power3.out',
                    onUpdate: () => {
                        setCursorVisibility(false);
                    },
                    onComplete: () => {
                        setCursorVisibility(false);

                        window.setTimeout(() => {
                            done();
                        }, 5000);
                    },
                });
            }
        },

        onLeave: async (el, done) => {
            const { gsap } = useGsap();

            if (el) {
                gsap.fromTo(el, {
                    scale: 1,
                    autoAlpha: 1,
                }, {
                    scale: 1.5,
                    autoAlpha: 0,
                    duration: 0.6,
                    ease: 'power3.in',
                    onUpdate: () => {
                        setCursorVisibility(false);
                    },
                    onComplete: () => {
                        done();
                    },
                });
            }
        },
    },
});

/*
  Dependencies
*/
const { locale, defaultLocale } = useI18n();
const route = useRoute();
const storyblokApi = useStoryblokApi();

/*
  Initialize variables
*/
const version = inject('storyblokVersion', 'draft');

const replacedPath = route.path.replace(`/${locale.value}`, '');
const path = replacedPath === '/' || replacedPath === '' ? '/home' : replacedPath;
const language = locale.value === defaultLocale ? '' : locale.value;

/*
  Fetch Storyblok Data
*/
const { data: response, error, execute } = await useAsyncData(
    `storyblok-story-data-${path}`,
    () => storyblokApi.get(
        `cdn/stories${path}`,
        {
            version,
            language,
        },
    ),
    {
        immediate: false,
    },
);

const { find } = useStrapi();

const { data: submissionData, execute: executeGetSubmission } = useLazyAsyncData(
    'submission',
    () => find('submissions', {
        populate: 'image',
        filters: {
            slug: route.path,
            is_approved: true,
        },
    }),
    {
        transform: (res) => {
            if (res.data?.length === 0) {
                return null;
            }

            const entry = res.data[0];
            const image = entry.attributes?.image?.data?.attributes;
            const { type } = entry.attributes;
            return {
                id: entry.id,
                title: type === 'art' ? entry.attributes.art_title : null,
                artTitle: type === 'art' ? entry.attributes.art_title : null,
                description: entry.attributes.description,
                artistName: entry.attributes.artist_name,
                type,
                image: {
                    src: image?.url,
                    alt: image?.alt,
                },
            };
        },
        server: false,
        immediate: false,
    },
);

if (path.includes('contributions')) {
    executeGetSubmission();
} else {
    execute();
}

watch(() => error.value, (newValue) => {
    if (newValue) {
        throw createError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true,
        });
    }
});

const computedData = computed(() => ({
    data: response?.value?.data,
    story: response?.value?.data?.story,
    content: response?.value?.data?.story?.content,
    seo: response?.value?.data?.story?.content?.seo,

}));

const mainContent = ref(null);
watch(() => computedData.value, (newValue) => {
    if (newValue.content) {
        mainContent.value = newValue.content.main_content;

        if (newValue.seo) {
            setSEOData(newValue.seo);
        }
    }

    if (version === 'draft' && newValue.story) {
        useStoryblokBridge(newValue.story.id, (evStory) => {
            mainContent.value = evStory.content.main_content;
        });
    }
}, {
    immediate: true,
    deep: true,
});
</script>
