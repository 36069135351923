<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="atom-input-image">
    <div class="atom-input-image__inner">
      <input
        ref="inputRef"
        :name="name"
        type="file"
        :multiple="false"
        class="atom-input-images__input"
        @change="onFileChange"
      />
      <!-- :accept="accept || null" -->

      <div class="atom-input-image__preview">
        <AtomImage
          v-if="queuedFile?.file"
          class="atom-input-images__image"
          behaviour="cover"
          :lazy="false"
          :provider="null"
          :image="{
            src: createObjectURL(queuedFile.file),
            alt: queuedFile.name,
          }"
        />

        <IonIcon
          v-else
          icon-name="camera"
          icon-color="var(--c-primary)"
          icon-width="15px"
        />
      </div>

      <AtomButton
        text="Bild auswählen"
        class="atom-input-image__upload"
        @click="onClickAdd"
      />

      <label
        :for="name"
        class="atom-input-images__label"
      >{{ label }}</label>
    </div>

    <AtomInputErrors
      v-if="hasErrors || forceShowFieldErrors"
      :errors="errors"
      :is-extended="hasErrors"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },

    isRequired: {
        type: Boolean,
        default: true,
    },

    requiredErrorText: {
        type: String,
        default: 'Dieses Feld ist erforderlich.',
    },

    toBigErrorText: {
        type: String,
        default: 'Das Bild ist zu gross.',
    },
});

const queuedFile = ref(null);
const inputRef = ref(null);

const onClickAdd = () => {
    inputRef.value.click();
};

const { validate } = useImageValidations();

const hasErrors = ref(false);

const isValidationPending = ref(false);
const validateFiles = async () => {
    isValidationPending.value = true;
    const { file } = queuedFile.value;

    if (!file) {
        return;
    }

    const result = await validate(file, {
        type: 'maxImageWeight',
        value: 7,
        message: props.toBigErrorText,
    });

    if (!result) {
        hasErrors.value = true;
    } else {
        hasErrors.value = false;
    }

    isValidationPending.value = false;
};

const onFileChange = async (event) => {
    queuedFile.value = {
        file: event.target.files[0],
        name: event.target.files[0].name,
    };

    await validateFiles();
};

/*
    Create object URL to display the queued images
*/
const createObjectURL = (file) => URL.createObjectURL(file);

/*
    Handle emits
*/
const emit = defineEmits(['set-input', 'set-error', 'on-reset']);
const errors = ref([]);
const handleEmits = () => {
    errors.value = [];
    errors.value = hasErrors.value ? [props.toBigErrorText] : [];

    if (props.isRequired && !queuedFile.value) {
        errors.value.push(props.requiredErrorText);
    }

    emit('set-error', {
        key: props.name,
        errors: errors.value,
    });

    emit('set-input', {
        name: props.name,
        value: queuedFile.value,
    });
};

handleEmits();

watch(() => isValidationPending.value, (newValue) => {
    if (!newValue) {
        handleEmits();
    }
});

const forceShowFieldErrors = inject('forceShowFieldErrors', null);

watch(() => forceShowFieldErrors.value, (newValue) => {
    if (newValue) {
        handleEmits();
    }
});

/*
    Reset value
*/
const resetValue = () => {
    queuedFile.value = null;
    emit('on-reset');
};

defineExpose({
    resetValue,
});
</script>

<style lang="scss" scoped>
.atom-input-image {
    display: flex;
    flex-direction: column;

}

.atom-input-image__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    row-gap: 10px;
}

.atom-input-image__preview {
    @include fluid-simple('height', 64px, 64px);
    @include fluid-simple('width', 64px, 64px);

    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--c-primary);
    border-radius: 10px;
}

.atom-input-image__upload {
    margin-top: 5px;
}

.atom-input-images__input {
    display: none;
}

.atom-input-images__label {
    display: none;
}
</style>
