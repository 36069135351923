<template>
  <div class="atom-input atom-input-textarea__wrapper">
    <div
      class="atom-input-textarea"
      :class="{
        'has-input': hasInput,
        'is-disabled': isDisabled,
        'has-error': showErrors,
      }"
    >
      <label class="atom-input-textarea__label" :for="name">
        {{ label + (isRequired ? ' *' : '') }}
      </label>

      <textarea
        :id="name"
        v-model="inputValue"
        :name="name"
        class="atom-input-textarea__input"
        @focus="setFocus(true)"
        @focusout="setFocus(false)"
        @mouseenter="setHover(true)"
        @mouseleave="setHover(false)"
      />
    </div>

    <transition name="fade-in-slow">
      <AtomInputErrors
        v-if="showErrors"
        :errors="errors"
        :is-extended="showErrorList"
      />
    </transition>
  </div>
</template>

<script setup>
/**
 * This component provides a textarea input field with label and validation support.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: © 2023 Nueva AG
 */

const props = defineProps({
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref('');

const setInitialValue = () => {
    inputValue.value = props.initialValue;
};

setInitialValue();

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error', 'on-reset']);

/* Watch input */
const isResetting = ref(false);
const handleData = (value) => {
    if (props.isDisabled) return;

    if (isResetting.value) {
        errors.value = [];
        isResetting.value = false;
        return;
    }

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

handleData(inputValue.value);

/*
    States
*/
const {
    setHover,
    setFocus,
    hasInput,
    showErrors,
    showErrorList,
    isRequired,
} = useFormFieldStates(inputValue, errors, props);

/*
    Reset value
*/
const resetValue = () => {
    isResetting.value = true;
    inputValue.value = '';
    emit('on-reset');
};

defineExpose({
    resetValue,
});
</script>

<style lang="scss" scoped>
.atom-input-textarea__wrapper {
    @include formFieldWrapper;
}
.atom-input-textarea {
    @include formFieldBasicsText;

    &:before {
        position: absolute;
        top: 1px;
        left: 8px;
        width: calc(100% - 16px);
        height: 40px;
        border-radius: 4px;
        background-color: var(--c-white);
        content: '';
    }
}

.atom-input-textarea__label {
    @include formFieldLabelText;
}

.atom-input-textarea__input {
    @include formFieldInputText;
    min-height: 175px;
}
</style>
