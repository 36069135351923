// Function to asynchronously get image dimensions
const _getImageDimensions = (file) => new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
        URL.revokeObjectURL(image.src); // Clean up object URL after loading
        resolve({ width: image.width, height: image.height });
    };
    image.onerror = (error) => {
        reject(error); // Handle any errors during image loading
    };
    image.src = URL.createObjectURL(file);
});

// Object to cache image dimensions
const imageDimensionsCache = new WeakMap();

const getImageDimensions = async (file) => {
    if (!imageDimensionsCache.has(file)) {
        const dimensions = await _getImageDimensions(file);
        imageDimensionsCache.set(file, dimensions);
    }
    return imageDimensionsCache.get(file);
};

const validators = {
    /*
        Images
    */
    async _minImageWidth(file, params) {
        const { width } = await getImageDimensions(file);
        return params.value < width;
    },

    async _maxImageWidth(file, params) {
        const { width } = await getImageDimensions(file);
        return width <= params.value;
    },

    async _minImageHeight(file, params) {
        const { height } = await getImageDimensions(file);
        return params.value < height;
    },

    async _maxImageHeight(file, params) {
        const { height } = await getImageDimensions(file);
        return height <= params.value;
    },

    _maxImageWeight(file, params) {
        return file.size <= params.value * 1024 * 1024;
    },
};

export default () => {
    const validate = async (
        valueToTest,
        validationObj,
    ) => validators[`_${validationObj.type}`](valueToTest, validationObj);

    return {
        validate,
    };
};
