/**
 * This function takes in a value, name, emit function, and an array of validations.
 * It validates the value against each validation in the array and pushes any error
 * messages to an errors array.
 * If there are no errors, it creates an object with the name and value and sets it to dataToEmit.
 * It then emits the data and errors using the provided emit function and returns
 * an object with the validation errors.
 *
 * @param {any} value - The value to validate.
 * @param {string} name - The name of the input field.
 * @param {function} emit - The emit function to use.
 * @param {array} validations - An array of validation objects to use.
 * @returns {object} - An object with the validation errors.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

export default (value, name, emit, validations) => {
    const { validate } = useValidations();

    const errors = [];
    let dataToEmit = null;

    /* loop through validations and push error messages */
    validations?.forEach((validation) => {
        const result = validate(value, validation);

        if (!result) {
            errors.push(validation.message);
        }
    });

    /* check errors and set data accordingly */
    dataToEmit = {
        name,
        value,
    };

    /* Emit Data */
    emit('set-input', dataToEmit);
    useStorybookAction('set-input', dataToEmit);

    /* Emit Errors */
    emit('set-error', {
        key: name,
        errors,
    });

    useStorybookAction('set-error', {
        key: name,
        errors,
    });

    return {
        validationErrors: errors,
    };
};
