<template>
  <div
    ref="wrapperRef"
    class="atom-input atom-input__wrapper"
    :class="{
      'is-disabled': isDisabled || isProgrammaticallyDisabled,
      'has-options-open': showOptions,
    }"
  >
    <div
      class="atom-input-select"
      :class="{
        'has-input': hasInput,
        'has-error': showErrors,
      }"
    >
      <label class="atom-input-select__label" :for="name">
        {{ label + (isRequired ? ' *' : '') }}
      </label>

      <input
        :id="name"
        v-model="inputValue"
        :name="name"
        tabindex="-1"
        class="atom-input-select__input"
        @focus="setFocus(true)"
        @focusout="setFocus(false)"
        @mouseenter="setHover(true)"
        @mouseleave="setHover(false)"
      >

      <IonIcon
        class="atom-input-select__icon has-pointer is-right"
        icon-name="chevron-down"
      />
    </div>

    <button
      type="button"
      class="atom-input-select__trigger"
      @click="toggleOptions(false)"
    />

    <div
      ref="optionsRef"
      class="atom-input-select__options"
    >
      <div
        v-for="(option, index) in options"
        :key="option.value"
        class="atom-input-select__option"
        :class="{
          'is-selected': selectedOption === option.value,
        }"
      >
        <span
          class="atom-input-select__option-divider"
          :class="{ 'is-first': index === 0 }"
        />
        <button
          type="button"
          class="atom-input-select__option-button"
          @click="selectOption(option)"
        >
          <span class="atom-input-select__option-background" />
          <span class="atom-input-select__option-label">{{ option.label }}</span>
        </button>
      </div>
      <span class="atom-input-select__option-divider is-last" />
    </div>

    <transition name="fade-in-slow">
      <AtomInputErrors
        v-if="showErrors"
        :errors="errors"
        :is-extended="showErrorList"
      />
    </transition>
  </div>
</template>

<script setup>
/**
 * This component provides a select input field with label, icon, and validation support.
 *
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {array} [props.options=[]] - An array of options to display in the select field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */
const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
    },

    name: {
        type: String,
        required: true,
    },

    initialValue: {
        type: String,
        default: '',
    },

    options: {
        type: Array,
        default: () => [],
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
    },
});

/*
    Intialze formfield
*/
const wrapperRef = ref(null);
const errors = ref([]);
const inputValue = ref('');
const selectedOption = ref(null);
const selectedPosition = useCookie('selected-position');
const isProgrammaticallyDisabled = ref(false);

const setInitialValue = () => {
    if (props.initialValue) {
        isProgrammaticallyDisabled.value = !!selectedPosition.value?.assignedTo;
        selectedOption.value = selectedPosition.value
            ? compileWithHandlebars(
                props.initialValue,
                selectedPosition.value ? selectedPosition.value : {},
            )
            : props.initialValue;

        if (selectedOption.value) {
            inputValue.value = props.options.find(
                (option) => option.value === selectedOption.value,
            )?.label;
        }
    }
};

setInitialValue();

/*
    Handle input
*/

const emit = defineEmits(['set-input', 'set-error', 'on-reset']);

/* Watch input */
const isResetting = ref(false);
const handleData = (value) => {
    if (props.isDisabled) return;

    if (isResetting.value) {
        errors.value = [];
        isResetting.value = false;
        return;
    }
    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => selectedOption.value, (newValue) => {
    handleData(newValue);
});

handleData(selectedOption.value);

/*
    States
*/
const {
    setHover,
    setFocus,
    hasInput,
    showErrors,
    showErrorList,
    isRequired,
} = useFormFieldStates(inputValue, errors, props);

/*
    Custom Logic
*/
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    showOptions.value = forceClose ? false : !showOptions.value;
    optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

/* handle selected options */
const selectOption = (option) => {
    selectedOption.value = option.value;
    inputValue.value = option.label;
    toggleOptions(true);
};

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});

/*
    Reset value
*/
const resetValue = () => {
    isResetting.value = true;
    selectedOption.value = null;
    inputValue.value = '';
    emit('on-reset');
};

defineExpose({
    resetValue,
});
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;

    &.is-disabled {
        cursor: not-allowed;
        opacity: 0.25;
        pointer-events: none;
    }
}

.atom-input-select {
    @include fluid-simple(--horizontal-padding, 20px, 24px);
    @include formFieldBasicsText;

    &.has-icon-left {
        @include fluid-simple(--horizontal-padding, 56px, 74px);
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.atom-input-select__label {
    @include formFieldLabelText;
}

.atom-input-select__input {
    @include formFieldInputText;
    pointer-events: none;
}

.atom-input-select__icon {
    @include formFieldInputIcon(false);
    transition: transform 0.5s var(--ease-in-out--back);

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-input-select__trigger {
    @include fluid-simple('height', 60px, 60px);
    @include z-index('selectTrigger');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.atom-input-select__options {
    @include fluid-simple('--options-height', 60px, 60px);
    position: absolute;
    top: calc(var(--options-height) - 4px);
    overflow: hidden;
    width: 100%;
    height: v-bind(optionsHeight);
    padding-top: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--c-primary);
    border-radius: 10px;
    border-top: none;
    background: var(--c-white);
    background-color: var(--c-white);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    transition: height 0.5s var(--ease-out--quint);

    .is-disabled & {
        opacity: 0.2;
        pointer-events: none;
    }
}

.atom-input-select__option {
    @include fluid-simple('height', 60px, 60px);
    position: relative;
    opacity: 0;
    transition: opacity 0.3s var(--ease-out--sine);

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s var(--ease-in-out--sine);
    }
}

.atom-input-select__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 1px;
    margin: 0 auto;
    background: var(--c-primary);

    &.is-last {
        margin-bottom: 20px;
    }
}

.atom-input-select__option-button {
    width: 100%;
}

.atom-input-select__option-background {
    @include z-index('selectBackground');
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 0px;
    left: 0;
    border-radius: 10px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;

    .is-selected & {
        background-color: var(--c-primary--light);
    }
}

.atom-input-select__option-label {
    @include fluid-simple('line-height', 60px, 60px);
    @include z-index('selectLabel');
    position: relative;
    padding-left: 20px;
    color: var(--c-black);
    font-family: var(--f-family--primary);
    font-size: 15px;
    font-weight: var(--f-weight--400);
}
</style>
